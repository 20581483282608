// Variables
@import "../../assets/css/vars";

.pp {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin: 0 auto 10px;
}