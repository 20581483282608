// Variables
@import "../../assets/css/vars";

.student-main-page {
    padding: 30px 0;

    .no-items {
        margin-bottom: 30px;
        border: 4px dashed $dark-blue-two;
        text-align: center;
        font: $large-size-bold;
        color: $dark-blue-two;

        p {
            margin: 45px 0;
        }
    }

    .months {
        .single-month {
            height: 270px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
            text-align: center;
            justify-content: center;
            margin-bottom: 30px;
            transition: all 0.2s;

            svg {
                margin-bottom: 20px;

                path {
                    fill: $blue !important;
                }
            }

            h4 {
                color: $dark-blue;
                font: $large-size-black;
                margin-bottom: 0;
            }

            span {
                color: $yellow;
                margin-top: 25px;
                display: block;
                font: $med-size-sbold;
            }

            &:hover {
                background-color: $dark-blue-two;

                svg {
                    path {
                        fill: $white !important;
                    }
                }

                h4,
                span {
                    color: $white;
                }
            }
        }
    }
}