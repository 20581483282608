// Variables
@import "../../assets/css/vars";

.month-videos {
    background-color: #F6F6F6;
    padding: 66px 0;

    .videos-list {
        background-color: $white;
        border-radius: 7px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 560px;

        .video-item {
            min-height: 61px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 30px;
            cursor: pointer;

            h3 {
                font: $small-size-bold;
                color: $dark-blue-two;
                padding-left: 15px;
            }

            i {
                color: $dark-blue-two;
                font-size: 18px;
            }

            &:hover,
            &.active {
                background-color: $light-blue;

                i {
                    color: $blue;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            width: 10px;
            background-color: #e4e4e4;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #bbbbbb;
            border-radius: 10px;
        }

        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
    }
}

.video-details {
    .video-react {
        margin-bottom: 20px;
        border-radius: 7px;
        overflow: hidden;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    iframe {
        width: 100%;
        height: 400px;
        border-radius: 7px;
        border: none;
        margin-bottom: 20px;
    }

    h2 {
        color: $dark-blue;
        font: $large-size-black;
        margin-bottom: 14px;
    }

    .description {
        font: $small-size-sbold;
        color: $dark-blue-two;
    }

    .attachments {
        margin-top: 32px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 7px;
        overflow: hidden;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font: $small-size-bold;
            color: $dark-blue-two;
            width: 100%;
            background-color: $white;
            padding: 16px 20px;
            cursor: pointer;
        }

        .files {
            background-color: $white;
            padding: 16px 20px;
            display: none;

            .single-file {
                a {
                    color: $dark-blue-one;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font: $tiny-size-bold;
                    margin-bottom: 16px;

                    span {
                        i {
                            margin-left: 5px;
                        }

                        &.size {
                            direction: ltr;
                        }
                    }
                }

                &:last-of-type {
                    a {
                        margin-bottom: 0;
                    }
                }
            }

            &.active {
                display: block;
            }
        }
    }

    .comments {
        margin-top: 40px;

        h3 {
            font: $med-size-black;
            color: $dark-blue-two;
        }

        .comment {
            margin-bottom: 20px;
            position: relative;

            section {
                display: flex;
                word-break: break-all;

                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin-left: 10px;
                }

                div {
                    h2 {
                        font: $small-size-black;
                        margin-bottom: 5px;
                        margin-top: 3px;
                    }

                    p {
                        font: $tiny-size-reg;
                        color: $dark-blue-two;
                    }
                }
            }

            .controls {
                position: absolute;
                left: 0;
                top: 0;
                display: flex;

                a {
                    color: $white;
                    background-color: $dark-blue-two;
                    padding: 2px 12px;
                    border-radius: 7px;
                    font: 300 14px Cairo;
                    margin-right: 10px;
                    display: flex;
                    align-items: center;

                    i {
                        margin-left: 5px;
                    }

                    &.delete {
                        background-color: $warningTxt;
                    }
                }
            }

            &.reply {
                margin-right: 65px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;

                section {
                    div {
                        h2 {
                            span {
                                background-color: $blue;
                                color: #fff;
                                font-size: 12px;
                                padding: 1px 5px;
                                border-radius: 5px;
                                margin-right: 3px;
                            }
                        }
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 19px;
                    height: 2px;
                    background-color: #d4d4d4;
                    top: 37px;
                    right: -33px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    height: 32px;
                    width: 2px;
                    background-color: #d4d4d4;
                    top: 5px;
                    right: -33px;
                }
            }
        }

        .write-comment {
            form {
                input {
                    width: 100%;
                    height: 60px;
                    border-radius: 7px;
                    padding: 5px 20px;
                    border: 1px solid $dark-blue-two;
                    color: $dark-blue-two;
                    outline-color: $blue;
                    font: $small-size-bold;

                    &::placeholder {
                        color: $dark-blue-two;
                    }
                }

                button {
                    border: none;
                    width: 100%;
                    border-radius: 7px;
                    height: 60px;
                    background-color: $blue;
                    color: $white;
                    font: $small-size-bold;

                    &.loading {
                        background-color: $dark-blue-two;
                    }

                    @media (max-width: 768px) {
                        margin-top: 10px;
                    }
                }
            }

            &.write-reply {
                form {
                    button {
                        background-color: $dark-blue-two;
                    }
                }
            }
        }
    }
}

.overlay {
    position: relative;

    span {
        position: absolute;
        z-index: 9;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        font: $large-size-black;
    }
}