// Variables
@import "../../assets/css/vars";

.page-header {
    background-color: $dark-blue;
    height: 220px;
    
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        h2 {
            color: $white;
            font: $big-size-black;
        }
    }
}