// Variables
@import "../../assets/css/vars";

.gallery {
    padding: 50px 0;

    .block {
        h3 {
            font: $large-size-bold;
            color: $dark-blue;
        }
    }

    .pswp-thumbnails {
        display: flex;
        margin: 0 -15px;
        flex-wrap: wrap;

        .pswp-thumbnail {
            width: 25%;
            padding: 0 15px;
            margin-bottom: 30px;
            flex: 0 0 25%;
            max-width: 25%;

            img {
                width: 100%;
                height: 192px;
                object-fit: cover;
                object-position: center;
                cursor: pointer;
                border-radius: 7px;
            }

            @media (min-width: 768px) and (max-width: 992px) {
                width: 33.3333%;
                margin-bottom: 20px;
            }

            @media (min-width: 414px) and (max-width: 768px) {
                width: 50%;
                margin-bottom: 20px;
            }

            @media (max-width: 768px) {
                flex: unset !important;
                max-width: 100%;
            }
        }

        @media (max-width: 414px) {
            display: unset;

            .pswp-thumbnail {
                width: 100%;
                margin-bottom: 20px;
                padding: 0;
            }
        }
    }

    .videos {
        .video {
            width: 100%;
            border-radius: 7px;
            background-size: cover;
            background-position: center;
            height: 192px;
            overflow: hidden;
            cursor: pointer;
            margin-bottom: 30px;

            .layer {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.4);
                position: relative;

                i {
                    color: $white;
                    font-size: 30px;
                }
            }
        }

        .overlay {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.85);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;

            .container,
            .row {
                height: 100%;
            }

            .video-react {
                border-radius: 8px;
            }

            i.fa-times {
                color: $white;
                font-size: 30px;
                cursor: pointer;
                position: absolute;
                right: 30px;
                top: 30px;
            }
        }
    }

    .pswp__caption__center {
        text-align: center;
    }
}